import React, { ChangeEventHandler, KeyboardEventHandler }from 'react';
import styled from 'styled-components';

const StyledTextInput = styled.div`
position:relative;
margin: 15px;

input {
  background: #f1f1f1;
  border: 0;
  padding: 0 1.4rem;
  right: 0;
  height: 40px;
  font-size: 13px;
  width: 100%;
  border-radius: 0 2px 2px 0;
  box-sizing: border-box;
  position: relative;
  color: rgba(0, 0, 0, 0.87);
}

span {
  position: absolute;
  border: solid 1px #f1f1f1;
  border-radius: 5px 0 0 5px;
  z-index: 2;
  padding: 9px;
  background-color:white;
}

.txtbox-with-child{
  padding-left:15%;
}
`;
interface TextInputProps {
  text: string;
  type: string;
  value?: string;
  children?: React.ReactElement;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const formatStringToId = (s: string) => {
  return (s.replace(/ /g, '-').replace(/-{2,}/g, '-').toLocaleLowerCase());
}

export const TextInput = ({ text, type, value, children, onKeyDown, onChange }: TextInputProps) =>{
  return (
    <StyledTextInput>
      {children &&
        <span aria-hidden='true'>
          {children}
        </span>
      }
      <input className={children ? 'txtbox-with-child' : ''}
        onKeyDown={onKeyDown}
        onChange={onChange}
        type={type}
        placeholder={text}
        value={value}
        id={`${formatStringToId(text)}-txt`
        }>
      </input>
    </StyledTextInput>
  )
}

export default TextInput;
