import React, { ChangeEvent, useState, KeyboardEvent } from 'react';
import TextInput from './ui/input';
import { ModalBackdrop, ModalPromptTypes, Spinner } from '@lawcpd/learner/shared/ui';
import { useFirebaseAuth } from 'support-provider';
import { RecaptchaVerifier } from '@firebase/auth';
import styled from 'styled-components';

const StyledError = styled.div`
  background-color: #fa9d96;
  border: 1px solid #fc6458;
  color: white;
  text-align: center;
  margin: 0px 20px;
  padding: 5px 0px;
  width: 90%;
`;

interface EnrollPhoneMFAProps {
  recaptcha?: RecaptchaVerifier;
}

export const EnrollPhoneMFA = (props: EnrollPhoneMFAProps) => {
  const
    [ loading, setLoading ] = useState(false),
    [ enrollmentId, setEnrollmentId ] = useState(''),
    [ phoneNumber, setPhoneNumber ] = useState(''),
    [ inputVerificationCode, setInputVerificationCode ] = useState(false),
    { enrollError, enrollUserToPhoneMFA, verifyEnrollmentPhoneCode } = useFirebaseAuth();

  const sendVerificationCodeHandler = () => {
    const formattedPhoneNumber = `+61${phoneNumber}`
    // Set props.parentState that dictates is authenticated when success for all process
    setLoading(true);
    setInputVerificationCode(true);
    enrollUserToPhoneMFA(formattedPhoneNumber, props.recaptcha)
    .then((id) => {
      setLoading(false);
      if(id) setEnrollmentId(id);
    })
    .catch((e) =>{
      setLoading(false);
      setInputVerificationCode(false);
    })
  }

  const textInputHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      sendVerificationCodeHandler();
    }
  }

  const phoneNumberHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const
      currentInput = e.target.value,
      phoneRegex = new RegExp(/^[0-9\b]{0,9}$/);

    if((phoneRegex.test(currentInput) || currentInput === '')){
      setPhoneNumber(currentInput);
    }
  }

  return (
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={('prompt' as ModalPromptTypes)}
      title='Enroll your phone to 2FA'
      onConfirm={sendVerificationCodeHandler}
      confirmText='Send Verification Code'
      className='login-modal'
      >
      {loading && <Spinner />}
      {!loading &&
        <div>
          {enrollError && <StyledError>{enrollError}</StyledError>}
          <TextInput
            type='text'
            text='Phone Number'
            value={phoneNumber}
            onKeyDown={textInputHandler}
            onChange={phoneNumberHandler}
            >
            <b>+61</b>
          </TextInput>
        </div>
      }
      {inputVerificationCode && enrollmentId && !enrollError &&
        <PhoneVerificationCode
          verificationHandler={(token) => verifyEnrollmentPhoneCode(enrollmentId, token)}
          />
      }
    </ModalBackdrop>
  )
}
interface PhoneVerificationCodeProps {
  resendCodeHandler?: () => void;
  verificationHandler?: (t: string) => void;
}

export const PhoneVerificationCode = (props: PhoneVerificationCodeProps) => {

  const
    { verifyError } = useFirebaseAuth(),
    { resendCodeHandler, verificationHandler } = props;

  const verificationCodeHandler = () => {
    const verificationCodeInput = document.getElementById('verification-code-txt') as HTMLInputElement;

    try{
      verificationHandler(verificationCodeInput.value);
    }
    catch(error){
      console.error(error);
    }
  }

  const textInputHandler = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      verificationCodeHandler();
    }
  }

  return (
    <ModalBackdrop
      backdropId='backdrop-root'
      overlayId='overlay-root'
      type={('prompt' as ModalPromptTypes)}
      title='Verify the code sent to your phone'
      onConfirm={verificationCodeHandler}
      confirmText='OK'
      className='login-modal'
      >
      {verifyError && <StyledError>{verifyError}</StyledError>}
      <a onClick={resendCodeHandler}>Resend code to your phone</a>
      <TextInput type='text' text='Verification Code' onKeyDown={textInputHandler}/>
    </ModalBackdrop>
  )
}
