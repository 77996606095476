import React from 'react';
import ReactDOM from 'react-dom/client';
import { EnvironmentProvider } from '@lawcpd/learner/shared/provider';
import { FirebaseAuthProvider } from 'support-provider';

import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { environment } from './environments/environment';

import App from './app/app';
import { FeatureFlagsProvider } from '@lawcpd/feature-flags/provider';


const NoMatch = () => {

  return (
    <Navigate
      to={{
        pathname: '/'
      }}
    />
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <EnvironmentProvider environment={environment}>
    <FeatureFlagsProvider useLearnerFF={false}>
      <FirebaseAuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="*" element={NoMatch()} />
          </Routes>
        </BrowserRouter>
      </FirebaseAuthProvider>
    </FeatureFlagsProvider>

  </EnvironmentProvider>
);
