import React, { useEffect, useState } from 'react';
import TextInput from './ui/input'
import styled from 'styled-components';
import LawCPDLogo from '../assets/logo.png';
import { EnrollPhoneMFA, PhoneVerificationCode } from './phone-verification';
import { useFirebaseAuth } from 'support-provider';
import { Spinner } from '@lawcpd/learner/shared/ui';
import { invisibleRecaptchaVerifier } from '@lawcpd/firebase';
import { RecaptchaVerifier } from '@firebase/auth';


const StyledApp = styled.div`
  font-family: sans-serif;
  min-width: 300px;
  max-width: 600px;
  max-height: 400px;
  min-height: 300px;
  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: #fff;
  border-radius: 5px;

  footer button {
    width: 100%;
    border: none;
    border-radius: 0 0 5px 5px;
    font-weight: bold;
    background-color: rgb(243, 112, 33);
    color: white;
    text-align: center;
    padding: 20px 0px;
    bottom: 0;
    position: absolute;
    cursor: pointer;
    text-select: none;
  }

  header {
    text-align: center;
    margin: 0 auto;
  }

  header img {
    vertical-align: middle;
  }
`;

const StyledError = styled.div`
  background-color: #fa9d96;
  border: 1px solid #fc6458;
  color: white;
  text-align: center;
  margin: 0px 20px;
  padding: 5px 0px;
  width: 90%;
`;

export const App = () => {
  const
    [ loginFlow, setLoginFlow ] = useState(false),
    {
      firebaseApp, error, enrollError,
      verifyError, loading, isMFASetUp,
      logInWithEmail, verifyMFAPhoneCode,
      resendPhoneCodeSignIn
    } = useFirebaseAuth(),
    [ recaptcha, setRecaptcha ] = useState<RecaptchaVerifier>();


  const loginHandler = () => {
    const
      user = document.getElementById('username-txt') as HTMLInputElement,
      pass = document.getElementById('password-txt') as HTMLInputElement;

    logInWithEmail(user.value, pass.value, recaptcha);
    setLoginFlow(true);
  }

  const textInputHandler = (e) => {
    if(e.key === 'Enter'){
      e.preventDefault();
      loginHandler();
    }
  }

  // Initialize recaptcha
  useEffect(() => {
    if(firebaseApp){
      setRecaptcha(invisibleRecaptchaVerifier(firebaseApp.getAuth(), 'recaptcha'));
    }
  }, [firebaseApp])

  // Reset recaptcha when error occurs
  useEffect(() => {
    if(error){
      setLoginFlow(false);
    }

    if(error || enrollError || verifyError){
      try{
        if(recaptcha) {
          recaptcha.clear();
          const
            recaptchaElem = document.getElementById('recaptcha'),
            recaptchaWrapper = document.getElementById('recaptcha-container');

          // Need to remove the old container when recreating the recaptcha
          recaptchaWrapper.removeChild(recaptchaElem);

          const newRecaptchaElem = document.createElement('div');
          newRecaptchaElem.setAttribute('id','recaptcha');

          recaptchaWrapper.appendChild(newRecaptchaElem);

          const newRecaptcha = invisibleRecaptchaVerifier(firebaseApp.getAuth(), 'recaptcha');
          setRecaptcha(newRecaptcha);
        }
      }
      catch(err){
        console.error('Error setting up captcha: ' + err.message)
      }
    }
  }, [error, enrollError, verifyError]);

  return (
    <StyledApp>
    {!loading && !isMFASetUp && loginFlow && !error &&
      <EnrollPhoneMFA recaptcha={recaptcha}/>
    }
    {!loading && isMFASetUp && loginFlow && !error &&
      <PhoneVerificationCode
        resendCodeHandler={() => resendPhoneCodeSignIn(recaptcha)}
        verificationHandler={verifyMFAPhoneCode}
      />
    }
    <header>
      <div>
        <img src={LawCPDLogo} width='100px' height='100px'/>
        <span>Support Dashboard</span>
      </div>
    </header>
    {loading && <Spinner />}
    {!loading &&
      <div>
        {error && <StyledError>{error}</StyledError>}
          <TextInput type='text' text='Username' onKeyDown={textInputHandler}/>
          <TextInput type='password' text='Password' onKeyDown={textInputHandler}/>
        <footer>
          <button onClick={loginHandler}>Login &gt;</button>
        </footer>
      </div>
    }
    </StyledApp>
  );
};

export default App;
